<template>
  <b-overlay :show="loading">
    <b-tabs
      pills
      nav-class="mb-2"
    >
      <b-tab
        title="Detail"
        active
      >
        <b-row class="justify-content-center">
          <b-col
            lg="9"
            md="10"
            sm="12"
          >
            <div class="d-flex justify-content-end" />
            <b-card-actions
              title="Detail Peminjaman"
              class="mb-2"
              action-collapse
            >
              <table class="table">
                <tr>
                  <td>Peminjam</td>
                  <th>{{ peminjaman.peminjam ? peminjaman.peminjam.nama_lengkap : '' }}</th>
                </tr>
                <tr>
                  <td>Nama Aset</td>
                  <th>{{ peminjaman.asset ? peminjaman.asset.nama : '' }}</th>
                </tr>
                <tr>
                  <td>Jumlah Yang Dipinjam</td>
                  <th>{{ peminjaman.jumlah }}</th>
                </tr>
                <tr>
                  <td>Satuan Aset</td>
                  <th>{{ peminjaman.asset && peminjaman.asset.satuan ? peminjaman.asset.satuan.satuan : '' }}</th>
                </tr>
                <tr>
                  <td>Lokasi</td>
                  <th>{{ peminjaman.lokasi }}</th>
                </tr>
              </table>
              <br><br>
              <br>
              <div
                v-if="parseInt(peminjaman.jumlah) > 0"
                class="d-flex justify-content-end"
              >
                <b-button
                  variant="outline-primary"
                  @click.prevent="doPengembalian"
                >
                  Kembalikan Peminjaman
                </b-button>
              </div>
            </b-card-actions>

            <b-card-actions
              collapsed
              title="Lainnya"
              action-collapse
            >
              <a
                v-if="history_peminjamans.length > 0"
                href="#"
                class="text-danger mb-2"
                @click.prevent="confirmCancel"
              >
                <strong>Batalkan Peminjaman?</strong>
              </a>
            </b-card-actions>
          </b-col>
        </b-row>
      </b-tab>
      <b-tab title="Riwayat Peminjaman">
        <table-riwayat />
      </b-tab>
    </b-tabs>

    <!-- Modal -->
    <pengembalian-modal @submitPengembalian="submitPengembalian" />
    <!-- / -->
  </b-overlay>
</template>
<script>
import {
  BTab, BTabs, BOverlay, BRow, BCol, BCard, BButton,
} from 'bootstrap-vue'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import { mapState } from 'vuex'
import TableRiwayat from './components/Riwayat.vue'
import PengembalianModal from './components/PengembalianModal.vue'

export default {
  components: {
    BTab, BTabs, BOverlay, BRow, BCol, BCard, BButton, TableRiwayat, BCardActions, PengembalianModal,
  },
  data: () => ({
    loading: false,
    peminjaman: {},
    confirmOption: {
      title: 'Anda yakin?',
      text: 'Anda akan membatalkan peminjaman ini',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Ya',
      cancelButtonText: 'Batal',
      customClass: {
        confirmButton: 'btn btn-primary',
        cancelButton: 'btn btn-outline-danger ml-1',
      },
      buttonsStyling: false,
      pengembalians: [],
    },
  }),
  methods: {
    async submitPengembalian(payload) {
      payload.id_peminjaman = this.$route.params.id
      try {
        this.loading = true
        await this.$store.dispatch('shippingOrder/savePengembalian', [payload])
        this.loading = false
        this.$bvModal.hide('pengembalian-modal')
        this.displaySuccess({
          message: 'Aset yang dipinjam berhasil dikembalikan',
        })
        setTimeout(() => {
          this.$router.push('/asset-pengembalian')
        }, 1000)
      } catch (e) {
        this.loading = false
        this.displayError(e)
        return false
      }
    },
    async getPenyimpanan(asset_id) {
      const params = {
        asset_id,
      }
      if (this.myGudang) params.gudang_id = this.myGudang.id
      const penyimpanans = await this.$store.dispatch('shippingOrder/getStock', params)
      penyimpanans.map(item => {
        item.jumlah = 0
      })
      this.$store.commit('shippingOrder/SET_PENYIMPANAN_PEMINJAMAN', penyimpanans)
    },
    doPengembalian() {
      if (this.peminjaman.jumlah > 0) {
        this.$bvModal.show('pengembalian-modal')
      } else {
        this.displayError({
          message: 'Tidak dapat dikembalikan karena jumlah aset yang dipinjam 0!',
        })
        return false
      }
    },
    toggleLoading(value) {
      this.loading = value
    },
    async getPeminjamanById() {
      const params = this.$route.params.id
      const histories = await this.$store.dispatch('shippingOrder/getPeminjamanById', params)
    },
    confirmCancel() {
      this.$swal(this.confirmOption)
        .then(res => {
          if (res.value) {
            this.doCancelPeminjaman()
          }
        })
    },
    async doCancelPeminjaman() {
      const payload = {
        id: this.history_peminjamans[0].id,
        cancel: 1,
      }

      try {
        this.loading = true
        if (this.pengembalians.length > 0) {
          const payloads = this.pengembalians.map(item => ({ id: item.id, cancel: 1 }))
          await this.$store.dispatch('shippingOrder/savePengembalian', payloads)
        }
        await this.$store.dispatch('shippingOrder/cancelPeminjaman', [payload])
        this.loading = false
        this.displaySuccess({
          message: 'Peminjaman berhasil dibatalkan',
        })
        setTimeout(() => {
          this.$router.push('/shipping-order')
        }, 1000)
      } catch (e) {
        this.loading = false
        this.displayError(e)
        return false
      }
    },
    async checkPengembalian() {
      const params = { id_peminjaman: this.$route.params.id }
      const pengembalians = await this.$store.dispatch('shippingOrder/getPengembalian', params)
      this.pengembalians = pengembalians
    },
  },
  computed: {
    ...mapState('asset', ['history_peminjamans']),
  },
  created() {
    const peminjaman = this.$store.getters['shippingOrder/searchPeminjaman'](this.$route.params.id)
    if (!peminjaman) {
      this.$router.push('/shipping-order')
    }

    this.peminjaman = peminjaman
    this.checkPengembalian()
    this.getPeminjamanById()
    if (this.peminjaman.asset) {
      this.getPenyimpanan(this.peminjaman.asset.id)
    }
  },
}
</script>
