<template>
  <b-card>
    <b-row v-if="history_peminjamans.length > 0">
      <b-col
        md="6"
        sm="4"
        class="my-1"
      >
        <b-form-group class="mb-0">
          <label class="d-inline-block text-sm-left mr-50">Per page</label>
          <b-form-select
            id="perPageSelect"
            v-model="perPage"
            size="sm"
            :options="pageOptions"
            class="w-50"
          />
        </b-form-group>
      </b-col>
      <b-col
        md="6"
        class="my-1"
      >
        <b-form-group
          label="Filter"
          label-cols-sm="3"
          label-align-sm="right"
          label-size="sm"
          label-for="filterInput"
          class="mb-0"
        >
          <b-input-group size="sm">
            <b-form-input
              id="filterInput"
              v-model="filter"
              type="search"
              placeholder="Type to Search"
            />
            <b-input-group-append>
              <b-button
                :disabled="!filter"
                @click="filter = ''"
              >
                Clear
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </b-col>
      <b-col cols="12">
        <b-table
          striped
          hover
          responsive
          :per-page="perPage"
          :current-page="currentPage"
          :items="history_peminjamans"
          :fields="fields"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :sort-direction="sortDirection"
          :filter="filter"
          :filter-included-fields="filterOn"
        >
          <template #head(checkbox)>
            <b-form-checkbox v-model="allChecked" />
          </template>
          <template #cell(checkbox)="{item}">
            <b-form-checkbox
              v-model="dataSelected"
              :value="item"
            />
          </template>
          <template #cell(no)="{index}">
            {{ ++index }}
          </template>
          <template #cell(nama)="{item}">
            <strong v-if="item.asset">
              <router-link :to="`/asset/detail/${item.id}`">{{ item.asset.nama }}</router-link>
            </strong>
            <i
              v-else
              class="text-danger"
            >Aset tidak ditemukan</i>
          </template>
          <template #cell(kode)="{item}">
            <span v-if="item.asset">
              {{ item.asset.kode }}
            </span>
            <i
              v-else
              class="text-danger"
            >kode tidak ditemukan</i>
          </template>
          <template #cell(satuan)="{item}">
            <span v-if="item.asset && item.asset.satuan">
              {{ item.asset.satuan.satuan }}
            </span>
            <i
              v-else
              class="text-danger"
            >Satuan tidak ditemukan</i>
          </template>
        </b-table>
      </b-col>
      <b-col cols="12">
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          align="center"
          size="sm"
          class="my-0"
        />
      </b-col>
    </b-row>
    <div
      v-else
      class="alert alert-danger p-1"
    >
      Riwayat peminjaman aset ini belum ada.
    </div>
  </b-card>
</template>
<script>
import {
  BButton, BCard, BRow, BCol, BPagination, BTable, BFormInput, BFormCheckbox, BFormGroup, BInputGroup, BInputGroupAppend, BFormSelect,
} from 'bootstrap-vue'
import { mapState } from 'vuex'

export default {
  components: {
    BButton, BCard, BRow, BCol, BPagination, BTable, BFormInput, BFormCheckbox, BFormGroup, BInputGroup, BInputGroupAppend, BFormSelect,
  },
  data: () => ({
    fields: [
      { key: 'no', label: 'No', sortable: true },
      { key: 'nama', label: 'Aset', sortable: true },
      { key: 'kode', label: 'Kode' },
      { key: 'jumlah', label: 'Jumlah' },
      { key: 'satuan', label: 'Satuan' },
    ],
    perPage: 10,
    pageOptions: [10, 30, 50],
    totalRows: 1,
    currentPage: 1,
    sortBy: '',
    sortDesc: false,
    sortDirection: 'desc',
    filter: null,
    filterOn: [],
  }),
  computed: {
    ...mapState('asset', ['history_peminjamans']),
  },
}
</script>
